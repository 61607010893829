<template>
  <a
    href="#"
    :class="containerClass"
    :disabled="isPending"
    @click="handleClick"
  >
    <ButtonLoader v-if="isPending" />
    <span v-else :class="casinoIconFavouriteClass"></span>
  </a>
</template>
<script>
import { FAVOURITE_ICON, EMPTY_STR, EventType } from '@/constants'

const buttonClass = 'btn casino-btn casino-btn-default casino-buttons'
const CASINO_ICON_CLASS = 'casino-icon'
const CASINO_ICON_FAVORITE_ON = 'casino-icon-favorite-on'
const CASINO_ICON_FAVORITE_OFF = 'casino-icon-favorite-off'

export default {
  name: FAVOURITE_ICON,
  components: {
    ButtonLoader: () => import('@/components/ButtonLoader'),
  },
  props: {
    isButton: {
      type: Boolean,
      default: true,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    containerClass() {
      return this.isButton ? buttonClass : EMPTY_STR
    },
    casinoIconFavouriteClass() {
      return [
        CASINO_ICON_CLASS,
        this.isFavorite ? CASINO_ICON_FAVORITE_ON : CASINO_ICON_FAVORITE_OFF,
      ]
    },
  },
  methods: {
    handleClick() {
      this.$emit(EventType.CLICK)
    },
  },
}
</script>
